import './_imports.js';


// Bootstrap
import '../../../../../../../development/node/node_modules/bootstrap/dist/js/bootstrap.bundle.js';

// Monster
import '../../../../../../../development/node/node_modules/@schukai/monster/source/components/form/reload.mjs';
import "../../../../../../../development/node/node_modules/@schukai/monster/source/components/form/message-state-button.mjs"

// Bestellen
import "./modules/_orderform.js"

// Kundenkonto
import "./_contact-form"

// Kundenkonto
import "./_account.js"

// Detail Seite
import "./_product_details.js"


// Prices START
import {Price} from './modules/_price.js';
import {Shoppingcart} from './modules/_order.js';

/**
* collect item iids
**/
let iidList = [];
const price = new Price(iidList);
class ErrepiCart extends Shoppingcart{}
const cartObj = new ErrepiCart();

document.querySelectorAll('[data-alvine-role="item"]').forEach(function (itemObj) {
     let iid = itemObj.getAttribute('data-iid');
     if (iid !== null) {
         iidList.push(iid);
     }
 });

/**
* call Price Api (Gallery, Product-details)
*/
if(iidList.length){
     price.iidList = iidList;
     price.init();  
}
// Prices END

document.addEventListener('shoppingCartChanged', function () {
     var loader = document.querySelector('#shoppingcart-loader');
     if(loader){
         loader.fetch().then(function (data) {
             //show cart popup
             $('.shopping__cart').addClass('shopping__cart__on');
             $('.body__overlay').addClass('is-visible');
         });
     }
});

//Shoppingcart popup START
if(document.getElementById("shoppingcart-loader")!=null) {
     document.getElementById("shoppingcart-loader").addEventListener("monster-fetched", function() {
         document.querySelectorAll('[data-alvine-role="position-remove"]').forEach(function (positionRemoveElement) {
             positionRemoveElement.addEventListener('click',function(){
                 var scidToRemove = positionRemoveElement.dataset.scid;
                 cartObj.removeFromCart(scidToRemove);
             });
         });
     });
 };
 //Shoppingcart popup END